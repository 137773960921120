@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');

html {
  font-size: 62.5%; // 62.5% of 16px = 10px -> 1rem = 10px
}

body {
  background-color: #ffffff;
  margin: 0;
}
